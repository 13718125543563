import { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { mobileUnitsApi } from '../../../../apis/disApi/mobileUnitsViewApi'

import { WIZARD_VIEW_TYPES } from './ViewDialogUtils'
import {
	getActivityView,
	loadAllActivityColumns,
	loadAllColumns,
	loadRecordsQuery,
} from 'components/data/dataServices'
import axios from 'axios'
import { ACTIVITY_ACTIONS } from 'reducers/activityReducer'
import { ACTIVITY_RECEIVE_TIME_KEY, VIEW } from './../../../../constants'
import { clone, isEmpty, isEqual } from 'lodash'
import {
	getAggregationModel,
	getRowGroupingModel,
	getSizedSessionStorage,
	getSortModel,
} from 'utils/functions/helpers'

export function useWizardQuery ({
	initViewData,
	viewType,
	environment,
	isAddNew,
	initColumns,
	initQueryView,
	initClientFilter,
	initSortModel,
	initRowGroupingModel,
	initAggregationModel,
	initExternalBgColorConditions,
	initRecords,
	initGridRows,
	initRecordsLoading,
	tab,
}) {
	const [viewData, setViewData] = useState(initViewData || {})
	const [loadingViewData, setLoadingViewData] = useState(false)
	const [allColumns, setAllColumns] = useState([])
	const [projectFormInfo, setProjectFormInfo] = useState({})
	const dispatch = useDispatch()
	const { activityModule } = useSelector((state) => state)
	const [allColumnsLoading, setAllColumnsLoading] = useState(false)
	const [queryView, setQueryView] = useState({})
	const [clientFilter, setClientFilter] = useState([])
	const [sortModel, setSortModel] = useState([])
	const [rowGroupingModel, setRowGroupingModel] = useState([])
	const [aggregationModel, setAggregationModel] = useState([])
	const [externalBgColorConditions, setExternalBgColorConditions] = useState({})
	const [records, setRecords] = useState([])
	const [gridRows, setGridRows] = useState([])
	const [recordsLoading, setRecordsLoading] = useState(false)

	const {
		action,
		//dataGrid: { columns, query, records, viewSession },
		dataGrid: { columns, query, viewSession },
		formDeleteSelection,
		activityRefresh,
		formColumns,
		formFilterConditions,
		formSelected,
		viewCreate,
		viewData: activityViewData,
		viewUpdate,
		rowsPerPage,
		clientFilters,
	} = activityModule

	const [listActiveFields, setListActiveFields] = useState([])

	const currentColumns = useMemo(() => {
		//if (viewType === WIZARD_VIEW_TYPES.MOBILE_UNITS || viewType === WIZARD_VIEW_TYPES.ACTIVITY) return viewData?.columns
		if (isEmpty(projectFormInfo)) {
			return viewData?.columns
		} else {
			return (isEmpty(initColumns) ? viewData?.columns : initColumns) || []
		}
	}, [projectFormInfo, viewData?.columns, initColumns])

	useEffect(() => {
		const activeFields = currentColumns ? currentColumns.map((curItem) => curItem.name) : []

		setListActiveFields(activeFields)
	}, [currentColumns])

	async function getMobileUnitsViewData () {
		const result = await mobileUnitsApi.getViewData(environment.apiToken)
		return result?.data
	}

	async function getMobileUnitsViewAllColumns () {
		setAllColumnsLoading(true)
		const result = await mobileUnitsApi.getColumns(environment.apiToken)
		setAllColumnsLoading(false)
		return result?.data
	}

	async function getAcivityViewAllColumns () {
		setAllColumnsLoading(true)
		const result = await loadAllActivityColumns(environment.apiToken)
		setAllColumnsLoading(false)
		return result?.data
	}

	async function getProjectFormAllColumns (proFrmInfo) {
		if (!proFrmInfo || !proFrmInfo.formKey) {
			return []
		}
		const result = await loadAllColumns(proFrmInfo.formKey, environment.apiToken)
		return result?.data || []
	}

	async function getActivityAllRecords (oViewData, oQuery) {
		setAllColumnsLoading(true)
		let oViewSession = true
		const result = await loadRecordsQuery(
			oViewData?.key,
			oViewSession,
			oQuery?.filter,
			environment.apiToken
		)
		setAllColumnsLoading(false)
		return result?.data?.records || []
	}

	async function getMobileUnitsAllRecords (oViewData, oQuery) {
		setAllColumnsLoading(true)
		let oViewSession = true
		const result = await mobileUnitsApi.getRecords(
			oViewData?.key,
			oViewSession,
			oQuery?.filter,
			environment.apiToken
		)
		setAllColumnsLoading(false)
		return result?.data?.records || []
	}

	function getProjectformInfo (viewData, isAddNew) {
		const projectKey = viewData?.projectKey
		const formKey = viewData?.formKey
		let project = environment.projects.find((item) => item.key === projectKey)
		if (!project && environment.lookups) {
			if (environment.lookups.length > 0) {
				project = environment.lookups?.find((item) => item.key === projectKey)
			} else {
				if (environment.lookups.key === projectKey) {
					project = environment.lookups
				}
			}
		}
		const projectName = project?.name || ''
		const form = environment.forms.find((item) => item.key === formKey)
		const formName = form?.name || ''
		return {
			projectKey,
			projectName,
			formKey,
			formName,
		}
	}

	async function getActivityViewData () {
		const cancelToken = axios.CancelToken.source()
		try {
			const response = await getActivityView(environment.apiToken, cancelToken)

			const responseData =
				response.data.queries[0].filter.conditions.findIndex(
					(item) => item.target === ACTIVITY_RECEIVE_TIME_KEY
				) !== -1
					? response.data
					: {
							...response.data,
							queries: response.data.queries.map((item, index) =>
								index === 0
									? {
											...item,
											filter: {
												...item.filter,
												conditions: [
													{
														target: ACTIVITY_RECEIVE_TIME_KEY,
														type: 'EQ',
														values: [],
														preset: 'TODAY',
														join: 'AND',
													},
													...item.filter.conditions,
												],
											},
									  }
									: item
							),
					  }
			const clientFilter = responseData?.clientFilter
				? JSON.parse(responseData?.clientFilter)
				: undefined
			if (clientFilter) {
				dispatch({
					type: ACTIVITY_ACTIONS.CLIENT_FILTERS,
					payload: {
						...clientFilters,
						activity: clientFilter,
					},
				})
			}
			dispatch({
				type: ACTIVITY_ACTIONS.DATA_GRID,
				payload: {
					columns: responseData.columns,
					query: responseData.queries[0],
					queryView: {
						queries: responseData.queries,
					},
				},
			})
			dispatch({
				type: ACTIVITY_ACTIONS.VIEW_DATA,
				payload: responseData,
			})

			return responseData
		} catch (err) {
			if (!axios.isCancel(err)) {
				//setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
			}
		}
	}

	function getClientFilter (oViewData) {
		return oViewData?.clientFilter ? JSON.parse(oViewData?.clientFilter) : []
	}

	function getWizardSortModel (oViewData, oAllColumns) {
		return getSortModel(oViewData?.columns, oViewData?.sorts)
	}

	function getWizardRowGroupingModel (oColumns) {
		return getRowGroupingModel(oColumns)
	}

	function getWizardAggregationModel (oColumns) {
		return getAggregationModel(oColumns)
	}

	function getColorConditions (oViewData) {
		const res =
			oViewData?.colorConditions === undefined || isEmpty(oViewData?.colorConditions)
				? {}
				: JSON.parse(oViewData?.colorConditions)
		return res
	}

	function compareProjectInfo () {
		let isChange = false

		let currProjectFormInfo = getProjectformInfo(initViewData, isAddNew)
		if (getViewType() === VIEW.VIEW_TAB_VIEWS) {
			if (
				!isEmpty(projectFormInfo) &&
				!isEmpty(currProjectFormInfo) &&
				projectFormInfo.projectKey === currProjectFormInfo.projectKey &&
				projectFormInfo.formKey === currProjectFormInfo.formKey
			) {
				return true
			}
		}

		return isChange
	}

	function getViewType () {
		if (isAddNew) {
			return viewType
		}

		return tab === VIEW.VIEW_TAB_VIEWS ? VIEW.VIEW_TAB_VIEWS : viewType
	}

	useEffect(() => {
		;(async () => {
			try {
				setLoadingViewData(true)
				let wizardViewData = {}
				let columns = []
				let proFrmInfo = {}
				let wizardQueryView = {}
				let wizardClientFilter = []
				let wizardSortModel = []
				let wizardRowGroupingModel = []
				let wizardAggregationModel = []
				let wizardExternalBgColorConditions = {}
				let wizardAllRecords = []
				let wizardViewType = getViewType()
				switch (wizardViewType) {
					case WIZARD_VIEW_TYPES.MOBILE_UNITS: {
						wizardViewData = await getMobileUnitsViewData()
						columns = await getMobileUnitsViewAllColumns()
						wizardQueryView = { queries: wizardViewData.queries }
						wizardClientFilter = getClientFilter(wizardViewData)
						wizardSortModel = getWizardSortModel(wizardViewData, columns)
						wizardRowGroupingModel = getWizardRowGroupingModel(columns)
						wizardAggregationModel = getWizardAggregationModel(columns)
						wizardExternalBgColorConditions = getColorConditions(wizardViewData)
						wizardAllRecords = await getMobileUnitsAllRecords(
							wizardViewData,
							wizardViewData?.queries[0]
						)
						break
					}
					case WIZARD_VIEW_TYPES.ACTIVITY: {
						if (isEmpty(activityViewData)) {
							wizardViewData = await getActivityViewData()
						} else {
							wizardViewData = activityViewData
						}
						wizardQueryView = { queries: wizardViewData.queries }
						columns = await getAcivityViewAllColumns()
						wizardClientFilter = getClientFilter(wizardViewData)
						wizardSortModel = getWizardSortModel(wizardViewData, columns)
						wizardRowGroupingModel = getWizardRowGroupingModel(columns)
						wizardAggregationModel = getWizardAggregationModel(columns)
						wizardExternalBgColorConditions = getColorConditions(wizardViewData)
						wizardAllRecords = await getActivityAllRecords(
							wizardViewData,
							wizardViewData?.queries[0]
						)
						break
					}
					default: {
						wizardViewData = initViewData
						proFrmInfo = getProjectformInfo(initViewData, isAddNew)
						//-------------------------------- use for tab view
						if (wizardViewType === VIEW.VIEW_TAB_VIEWS) {
							columns = initColumns
							wizardRowGroupingModel = initRowGroupingModel
							wizardAggregationModel = initAggregationModel
						}
						//--------------------------------
						wizardQueryView = initQueryView
						wizardClientFilter = initClientFilter
						wizardSortModel = initSortModel
						wizardExternalBgColorConditions = initExternalBgColorConditions
						wizardAllRecords = initRecords
					}
				}
				setViewData(wizardViewData)
				setAllColumns(columns)
				setProjectFormInfo(proFrmInfo)
				setQueryView(wizardQueryView)
				setClientFilter(wizardClientFilter)
				setSortModel(isEmpty(wizardSortModel) ? [] : wizardSortModel)
				setRowGroupingModel(isEmpty(wizardRowGroupingModel) ? [] : wizardRowGroupingModel)
				setAggregationModel(isEmpty(wizardAggregationModel) ? [] : wizardAggregationModel)
				setExternalBgColorConditions(wizardExternalBgColorConditions)
				setRecords(wizardAllRecords)
				setGridRows(wizardAllRecords)
				setLoadingViewData(false)
			} catch (err) {
				console.log(err)
				throw err
			}
		})()
	}, [viewType])

	useEffect(() => {
		if (!projectFormInfo || !projectFormInfo.formKey || compareProjectInfo() === true) return
		setAllColumnsLoading(true)
		loadAllColumns(projectFormInfo.formKey, environment.apiToken)
			.then((response) => {
				setAllColumns([...response.data])
				let wizardRowGroupingModel = []
				let wizardAggregationModel = []
				wizardRowGroupingModel = getRowGroupingModel([...response.data])
				wizardAggregationModel = getAggregationModel(columns)
				setRowGroupingModel(isEmpty(wizardRowGroupingModel) ? [] : wizardRowGroupingModel)
				setAggregationModel(isEmpty(wizardAggregationModel) ? [] : wizardAggregationModel)
			})
			.catch((err) => {
				setAllColumns([])
			})
			.finally(() => setAllColumnsLoading(false))
	}, [projectFormInfo, environment.apiToken])

	const resetData = (resetProjectFormInfo = false) => {
		if (resetProjectFormInfo) {
			setProjectFormInfo({})
		}
		setViewData({})
		setListActiveFields([])
		setAllColumns([])
	}
	return {
		viewData,
		loadingViewData,
		allColumns,
		projectFormInfo,
		allColumnsLoading,
		currentColumns,
		listActiveFields,
		queryView,
		clientFilter,
		sortModel,
		rowGroupingModel,
		aggregationModel,
		externalBgColorConditions,
		records,
		gridRows,
		recordsLoading,
		setProjectFormInfo,
		resetData,
		setListActiveFields,
		updateMobileUnitView: mobileUnitsApi.updateViewData,
	}
}
