import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { LoadingButton } from '@mui/lab'
import {
	InputLabel,
	FormControl,
	TextField,
	Select,
	MenuItem,
	RadioGroup,
	Radio,
	FormControlLabel,
	Alert,
	Typography,
} from '@mui/material'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import { VIEW } from '../../../../../constants'
import { WIZARD_VIEW_TYPES } from '../ViewDialogUtils'
const useStyles = makeStyles(() => ({
	headingLabel: {
		display: 'flex',
		paddingLeft: '10px',
		minHeight: '32px',
	},
	saveTheView: {
		textAlign: 'left',
	},
	saveTheViewRadio: {
		marginRight: '10px',
		position: 'relative',
		bottom: '-3px',
		width: '18px',
		height: '18px',
	},
	viewTabContener: {
		marginLeft: '30px',
		width: '80%',
	},
	viewTabItem: {
		marginBottom: '10px',
	},
}))

export const SaveTheViewDialog = (props) => {
	const [t] = useTranslation('common')
	const {
		tab,
		owners,
		hasManageRights,
		projectFormInfo,
		isAddNew,
		setIsOpenDialog,
		viewData,
		handleSaveApply,
		handleSaveAsApply,
		errorMessage,
		listActiveFields,
		viewType,
	} = props
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const [value, setValue] = useState('')
	const [isValidSave, setIsValidSave] = useState(false)
	const [saveType, setSaveType] = useState('')
	const [viewName, setViewName] = useState('')
	const [ownerKey, setOwnerKey] = useState('')
	const [progressSave, setProgressSave] = useState(false)
	const [isMobileUnit, setIsMobileUnit] = useState(false)

	function resetData() {
		setSaveType('')
		setViewName('')
		setOwnerKey('')
	}

	useEffect(() => {
		if (errorMessage && progressSave === true) {
			setProgressSave(false)
		}
	}, [errorMessage, progressSave])

	useEffect(() => {
		// add new mode
		const defaultSaveType = getDefaultSaveType(tab, viewData)

		if (viewData?.type === 'DEVICE') {
			// if (isAddNew === false && (tab === VIEW.VIEW_TAB_VIEWS || tab === 'tileBuilder')) {
			// 	setSaveType(defaultSaveType)
			// } else {
			setSaveType(VIEW.VIEW_SAVE_TYPE_ADD)
			setIsMobileUnit(true)
			//}
		} else {
			setSaveType(defaultSaveType)
		}
		//setSaveType(defaultSaveType)
		if (isAddNew || isEmpty(viewData)) return

		setViewName(viewData?.name)
		setOwnerKey(viewData?.ownerKey)
	}, [viewData])

	const handleCancel = () => {
		setIsOpenDialog(false)
		resetData()
	}

	const handleOpenSaveDialog = (event) => {
		//setIsOpenDialog(false)
		handleSaveData(event)
		//resetData()
	}

	const handleSaveData = async (event) => {
		setProgressSave(true)

		if (
			saveType === VIEW.VIEW_TYPE_EVERYONE ||
			saveType === VIEW.VIEW_TYPE_MYVIEW ||
			saveType === VIEW.VIEW_SAVE_TYPE_UPDATE
		) {
			await handleSaveApply(event, { saveType, ownerKey, viewName })
		} else {
			await handleSaveAsApply(event, { saveType, ownerKey, viewName })
		}
	}

	const changeType = (event) => {
		setSaveType(event.target.value)
	}
	const changeViewName = (event) => {
		setViewName(event.target.value)
	}
	const changeOwner = (event) => {
		setOwnerKey(event.target.value)
	}

	const changeValue = () => {
		if (VIEW.VIEW_TYPE_EVERYONE === saveType) {
			setValue(t('common:view.saveTypeEveryone'))
		} else if (VIEW.VIEW_TYPE_MYVIEW === saveType) {
			setValue(t('common:view.saveTypeMyView'))
		} else if (VIEW.VIEW_SAVE_TYPE_ADD === saveType || VIEW.VIEW_SAVE_TYPE_UPDATE === saveType) {
			let ownerName = owners.find((owner) => owner.key === ownerKey)?.name
			const saveAsValue =
				saveType === VIEW.VIEW_SAVE_TYPE_ADD
					? t('common:view.saveTypeAddUpdateNew').toLocaleLowerCase()
					: t('common:view.saveTypeAddUpdateUpdate').toLocaleLowerCase()
			setValue(
				t('common:view.saveTypeViewTab') +
					', name = "' +
					viewName +
					'", assign to = "' +
					ownerName +
					'", ' +
					saveAsValue
			)
		}
	}

	useEffect(() => {
		changeValue()
	}, [saveType, ownerKey, viewName])

	useEffect(() => {
		if (
			viewType === WIZARD_VIEW_TYPES.PROJECT_FORM &&
			(!projectFormInfo?.projectKey || !projectFormInfo?.formKey || !saveType)
		) {
			setIsValidSave(false)
			return
		}
		if (
			VIEW.VIEW_TYPE_EVERYONE !== saveType &&
			VIEW.VIEW_TYPE_MYVIEW !== saveType &&
			VIEW.VIEW_SAVE_TYPE_ADD !== saveType &&
			VIEW.VIEW_SAVE_TYPE_UPDATE !== saveType
		) {
			setIsValidSave(false)
			return
		}

		const isAddOrUpdateType =
			VIEW.VIEW_SAVE_TYPE_ADD === saveType || VIEW.VIEW_SAVE_TYPE_UPDATE === saveType
		if (isAddOrUpdateType && (!ownerKey || !viewName?.trim())) {
			setIsValidSave(false)
			return
		}

		if (!viewName?.trim()) {
			setIsValidSave(false)
			return
		}

		setIsValidSave(true)
	}, [projectFormInfo, saveType, ownerKey, viewName])

	function getDefaultSaveType(tab, viewData) {
		switch (tab) {
			case VIEW.VIEW_TAB_FORMS:
			case VIEW.VIEW_TAB_LOOKUPS:
				return VIEW.VIEW_TYPE_MYVIEW
			case VIEW.VIEW_TAB_VIEWS:
				//return VIEW.VIEW_SAVE_TYPE_ADD
				return isAddNew ? VIEW.VIEW_SAVE_TYPE_ADD : VIEW.VIEW_SAVE_TYPE_UPDATE
			case VIEW.TAB_TILE_BUILDER:
				return isEmpty(viewData) ? VIEW.VIEW_SAVE_TYPE_ADD : VIEW.VIEW_SAVE_TYPE_UPDATE
			default:
				return VIEW.VIEW_SAVE_TYPE_ADD
		}
	}
	return (
		<Dialog
			//target={'form-save-view'}
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={true}
			closeAfterTransition
			sx={{
				'& .MuiDialog-paper': {
					width: '23% !important',
					minWidth: '400px !important',
					maxWidth: '100% !important',
				},
				'& .MuiDataGrid-overlayWrapper': {
					minHeight: '100px',
				},
				'& .MuiDialogTitle-root': {
					padding: '16px 24px 0 !important',
				},
			}}
		>
			<DialogTitle id="alert-dialog-title">
				<Typography
					id="transition-modal-title"
					className={classes.headingLabel}
					variant="h6"
					align="left"
				>
					{t('common:formsData.saveView')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<div className={classes.saveTheView}>
					<FormControl
						sx={{
							display: 'flex',
							alignItems: 'left',
							paddingLeft: '55px !important',
							'& .MuiFormControlLabel-label': {
								paddingTop: '8px',
								fontSize: '0.875rem',
							},
						}}
					>
						<RadioGroup>
							{[VIEW.VIEW_TAB_FORMS, VIEW.VIEW_TAB_LOOKUPS].includes(tab) &&
								isMobileUnit === false && (
									<FormControlLabel
										value={VIEW.VIEW_TYPE_EVERYONE}
										disabled={
											(VIEW.VIEW_TAB_DEVICES !== tab &&
												VIEW.VIEW_TAB_FORMS !== tab &&
												VIEW.VIEW_TAB_LOOKUPS !== tab) ||
											!hasManageRights
										}
										control={
											<Radio
												className={classes.saveTheViewRadio}
												checked={saveType === VIEW.VIEW_TYPE_EVERYONE ? 'checked' : ''}
											/>
										}
										label={t('common:view.saveTypeEveryone')}
										onChange={changeType}
									/>
								)}
							{[VIEW.VIEW_TAB_FORMS, VIEW.VIEW_TAB_LOOKUPS].includes(tab) &&
								isMobileUnit === false && (
									<FormControlLabel
										value={VIEW.VIEW_TYPE_MYVIEW}
										control={
											<Radio
												className={classes.saveTheViewRadio}
												checked={saveType === VIEW.VIEW_TYPE_MYVIEW ? 'checked' : ''}
											/>
										}
										label={t('common:view.saveTypeMyView')}
										onChange={changeType}
									/>
								)}
							{[VIEW.VIEW_TAB_VIEWS].includes(tab) && isMobileUnit === false && (
								<FormControlLabel
									value={VIEW.VIEW_SAVE_TYPE_UPDATE}
									disabled={isAddNew === true}
									control={
										<Radio
											className={classes.saveTheViewRadio}
											checked={saveType === VIEW.VIEW_SAVE_TYPE_UPDATE ? 'checked' : ''}
										/>
									}
									label={t('common:view.saveTypeAddUpdateUpdate')}
									onChange={changeType}
								/>
							)}
							{[VIEW.VIEW_TAB_FORMS, VIEW.VIEW_TAB_VIEWS, VIEW.VIEW_TAB_LOOKUPS].includes(tab) &&
								isMobileUnit === false && (
									<FormControlLabel
										value={VIEW.VIEW_SAVE_TYPE_ADD}
										// disabled={VIEW.VIEW_TYPE_VIEWTAB !== saveType}
										control={
											<Radio
												className={classes.saveTheViewRadio}
												checked={saveType === VIEW.VIEW_SAVE_TYPE_ADD ? 'checked' : ''}
											/>
										}
										label={t('common:view.saveTypeAddUpdateNew')}
										onChange={changeType}
									/>
								)}
						</RadioGroup>
					</FormControl>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',

							'& .MuiFormControlLabel-label': {
								paddingTop: '8px',
								fontSize: '0.875rem',
							},
						}}
					>
						<FormControl className={classes.viewTabContener}>
							<div className={classes.viewTabItem}>
								<TextField
									//disabled={!saveAsChoice}
									id="save-as-input"
									label={t('common:view.viewName')}
									sx={{ mt: 2 }}
									size="small"
									value={viewName}
									onChange={changeViewName}
									fullWidth
									// disabled={VIEW.VIEW_TYPE_VIEWTAB != saveType}
									disabled={
										(tab === VIEW.VIEW_TAB_FORMS || tab === VIEW.VIEW_TAB_LOOKUPS) &&
										(VIEW.VIEW_TYPE_EVERYONE === saveType || VIEW.VIEW_TYPE_MYVIEW === saveType)
									}
								/>
							</div>
							<div className={classes.viewTabItem}>
								<FormControl fullWidth size="small">
									<InputLabel id="demo-simple-select-label">Assign to</InputLabel>
									<Select
										// labelId="demo-simple-select-label"
										// id="demo-simple-select"
										value={ownerKey}
										label={t('common:view.assignTo')}
										onChange={changeOwner}
										//size="small"
										disabled={
											(tab === VIEW.VIEW_TAB_FORMS || tab === VIEW.VIEW_TAB_LOOKUPS) &&
											(VIEW.VIEW_TYPE_EVERYONE === saveType || VIEW.VIEW_TYPE_MYVIEW === saveType)
										}
									>
										{owners.map((owner) => {
											return <MenuItem value={owner.key}>{owner.name}</MenuItem>
										})}
									</Select>
								</FormControl>
							</div>
						</FormControl>
					</div>
				</div>
				{errorMessage && (
					<Alert icon={false} severity="error">
						This is an error alert — check it out!
					</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} className={classes.icon}>
					{t('common:misc.cancel')}
				</Button>
				<LoadingButton
					className={classes.icon}
					loading={progressSave}
					style={{
						minWidth: '80px',
					}}
					disabled={!isValidSave || (listActiveFields !== null && listActiveFields.length === 0)}
					loadingPosition="start"
					onClick={handleOpenSaveDialog}
				>
					{t('common:misc.save')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

// export default SaveTheViewDialog
