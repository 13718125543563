import { useEffect, useState, useMemo, useContext, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material"
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import { isEmpty, cloneDeep } from 'lodash'

import CollapseComponent from '../../../../../../components/data/datagrid/CreatViewComponents/collapses/CollapseComponent'
import { ChooseAFormCollapse } from '../../../../../../components/data/datagrid/CreatViewComponents/collapses/ChooseAFormCollapse'
import { PrimaryFilterCollapse } from '../../../../../../components/data/datagrid/CreatViewComponents/collapses/PrimaryFilterCollapse'
import { AdditionalFiltersCollapse } from '../../../../../../components/data/datagrid/CreatViewComponents/collapses/AdditionalFiltersCollapse'
import { SortTheDataCollapse } from '../../../../../../components/data/datagrid/CreatViewComponents/collapses/SortTheDataCollapse'
import { GroupTheDataCollapse } from '../../../../../../components/data/datagrid/CreatViewComponents/collapses/GroupTheDataCollapse'
import { AddCalculationsCollapse } from '../../../../../../components/data/datagrid/CreatViewComponents/collapses/AddCalculationsCollapse'
import { ColorPickerCollapse } from '../../../../../../components/data/datagrid/CreatViewComponents/collapses/ColorPickerCollapse'

import { IconThemeContext } from '../../../../../../custom-components/context/IconThemesContext'
import LoadingSpinner from 'custom-components/LoadingSpinner'

import { dialogSettingsReducer } from './dialogSettingsReducer'
import {
    getAggregationModel,
    getRowGroupingModel,
} from 'utils/functions/helpers'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiButton-root': {
            textTransform: 'none',
        },
    },
    headingLabel: {
        display: 'flex',
        paddingLeft: '10px',
        minHeight: '32px',
    },
    buttonGroup: {
        '& .MuiToggleButtonGroup-grouped': {
            border: 0,
            textTransform: 'none',
            '&.Mui-disabled': {
                border: 0,
            },
        },
    },
    listBody: {
        maxHeight: '500px',
        overflowY: 'auto',
    },
    footer: {
        minHeight: '52px',
        '& .MuiButton-root': {
            textTransform: 'none !important',
        },
    },
    body: {
        backgroundColor: '#fff',
        borderRadius: '5px',
        height: '95%',
        maxHeight: '700px',
        overflowY: 'auto',
    },
    icon: (props) => ({
        color: props.color,
        '&:hover': {
            color: props.active.color,
            backgroundColor: 'transparent',
        },
    }),
    row: {
        width: '100%',
        height: 'auto',
        borderBottom: '1px solid #a8a8a8',
    },
    colLeft: {
        display: 'inline-block',
        width: '30%',
        textAlign: 'left',
        fontWeight: '500',
        paddingLeft: '10px',
        paddingTop: '10px',
        lineHeight: '30px',
    },
    colRight: {
        display: 'inline-block',
        width: '65%',
        textAlign: 'left',
        fontWeight: '500',
        color: '#b9b9b9',
    },
    collapseIcon: (props) => ({
        fontSize: 12,
        transform: 'rotate(270deg)',
        '&:hover': {
            color: props.active.color,
            backgroundColor: 'transparent',
        },
    }),
    contain: {
        display: 'none',
        marginLeft: '30%',
    },
    addNew: {
        height: '50px',
        width: '60px',
    },
}))

const generateFieldsValue = (allColumns, listActiveFields) => {
    let nameTmp = ''
    if (allColumns) {
        const tmp = allColumns.filter((item) => {
            return listActiveFields?.includes && listActiveFields?.includes(item.name)
        })
        nameTmp = tmp.map((item) => item.title).join(', ')
        return nameTmp
    }
}

function getProjectName(projects, lookups, projectKey) {
    const projectName = (projects?.find(item => item.key === projectKey))?.name
    if (!!projectName) return projectName

    if (isEmpty(lookups)) return ''
    const lookupsName = Array.isArray(lookups)
        ? (projects.find(item => item.key === projectKey))?.name
        : (lookups[projectKey]?.name)

    return lookupsName
}

export function TileSettingsDialog(props) {
    const {
        isOpen,
        setIsOpen,
        allDialogSettings,
        setAllDialogSettings,
        viewData,
        columns,
        queryView: initQueryView,
        clientFilter: initClientFilter,
        serverSortModel,
        fetchRecords,
    } = props

    const [t] = useTranslation('common')
    const { environment } = useSelector((state) => state)

    const { iconTheme } = useContext(IconThemeContext)
    const classes = useStyles(iconTheme)

    const [expandedAccordion, setExpandedAccordion] = useState('')

    const tileColumns = useMemo(() => {
        return columns.map((col) => ({
            ...col,
            name: col.field,
            type: col.type.toUpperCase()
        }))
    }, [columns])
    const [listActiveFields, setListActiveFields] = useState([])
    const [listActiveFieldsOptions, setListActiveFieldsOptions] = useState([])
    const listActiveFieldsValue = useMemo(() => {
        return generateFieldsValue(tileColumns, listActiveFields)
    }, [tileColumns, listActiveFields])

    const [aggregationModel, setAggregationModel] = useState({})
    const [rowGroupingModel, setRowGroupingModel] = useState([])

    const [settingsDialog, settingsDialogDispatch] = useReducer(dialogSettingsReducer, allDialogSettings)

    const [projectFormInfo, setProjectFormInfo] = useState({
        projectKey: '',
        projectName: '',
        formKey: '',
        formName: '',
    })
    const [queryView, setQueryView] = useState(initQueryView)
    const [clientFilter, setClientFilter] = useState(initClientFilter?.items)

    useEffect(() => {
        const prjFormInfo = {
            projectKey: viewData.projectKey,
            formKey: viewData.formKey
        }

        const allProjects = [...(environment?.projects || [])]
        if (isEmpty(allProjects)) {
            setProjectFormInfo((prev) => ({ ...prev, ...prjFormInfo }))
            return
        }

        prjFormInfo.projectName = getProjectName(environment?.projects, environment?.lookups, prjFormInfo.projectKey)

        const form = environment.forms?.find((item) => item.key === prjFormInfo.formKey)
        prjFormInfo.formName = form?.name || ''
        setProjectFormInfo((prev) => ({ ...prev, ...prjFormInfo }))
    }, [environment?.projects, environment?.lookups, environment?.forms])

    useEffect(() => {
        const activeFields = isEmpty(tileColumns) ? [] : tileColumns.map((curItem) => curItem.name)
        setListActiveFields(activeFields)
    }, [tileColumns])

    useEffect(() => {
        if (!tileColumns) return

        const aggregationModel = getAggregationModel(tileColumns)
        setAggregationModel(isEmpty(aggregationModel) ? [] : aggregationModel)

        const rowGroupingModel = getRowGroupingModel(tileColumns)
        setRowGroupingModel(isEmpty(rowGroupingModel) ? [] : rowGroupingModel)
    }, [tileColumns])

    useEffect(() => {
        if (isEmpty(tileColumns) || isEmpty(listActiveFields)) return

        const columnByActiveFieldsOrder = []
        for (const field of listActiveFields) {
            const column = tileColumns.find((col) => field === col.name)
            if (!column) continue

            columnByActiveFieldsOrder.push(column)
        }
        setListActiveFieldsOptions(columnByActiveFieldsOrder)
    }, [tileColumns, listActiveFields])

    // initdata for for primary collapse
    useEffect(() => {
        if (isEmpty(initQueryView) || isEmpty(initQueryView.queries[0].filter)) return

        if (isEmpty(allDialogSettings.primaryFilter)) {
            setQueryView(initQueryView)
            return
        }

        const newQueryView = cloneDeep(initQueryView)
        newQueryView.queries[0].filter.conditions = allDialogSettings.primaryFilter
        setQueryView(newQueryView)
    }, [allDialogSettings, initQueryView])


    // initdata for additional filters collapse
    useEffect(() => {
        if (!isEmpty(allDialogSettings?.additionalFilters)) {
            setClientFilter(allDialogSettings.additionalFilters)
            return
        }

        if (isEmpty(clientFilter)) return

        setClientFilter(clientFilter)
    }, [allDialogSettings, clientFilter])

    function handleOk(e) {
        e.preventDefault()
        setAllDialogSettings(settingsDialog)
        fetchRecords(settingsDialog)
        setIsOpen(false)
    }

    return (
        <Dialog
            target={'form-save-view'}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            closeAfterTransition
            sx={{
                '& .MuiDialog-paper': {
                    width: '55% !important',
                    maxWidth: '100% !important',
                },
                '& .MuiDataGrid-overlayWrapper': {
                    minHeight: '100px',
                },
                '& .MuiDialogTitle-root': {
                    padding: '16px 24px 0 !important',
                },
            }}
            onClose={() => setIsOpen(false)}
        >
            <DialogTitle>
                <Typography
                    id="transition-modal-title"
                    className={classes.headingLabel}
                    variant="h6"
                    align="left"
                >
                    {`View Filter`}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {environment?.isLoadingProjects ? (
                    // for load project form only
                    <LoadingSpinner />
                ) : (
                    <div>
                        <ChooseAFormCollapse
                            projectFormInfo={projectFormInfo}
                            setProjectFormInfo={() => { }}
                            resetViewData={() => { }}
                            expandedAccordion={expandedAccordion}
                            setExpandedAccordion={null}
                        />
                        <CollapseComponent
                            title={t('common:view.selectTheFields')}
                            value={listActiveFieldsValue}
                            expanded={false}
                            setExpandedAccordion={null}
                            content={null}
                        />
                        <PrimaryFilterCollapse
                            viewData={viewData}
                            environment={environment}
                            tab={'tile'}
                            query={viewData?.queries}
                            isAddNew={false}
                            queryView={queryView}
                            allColumns={tileColumns}
                            allColumnsLoading={false}
                            listActiveFields={listActiveFields}
                            saveSettingDispatch={settingsDialogDispatch}
                            expandedAccordion={expandedAccordion}
                            setExpandedAccordion={setExpandedAccordion}
                            conditions
                        />
                        <AdditionalFiltersCollapse
                            clientFilter={clientFilter}
                            allColumns={tileColumns}
                            allColumnsLoading={false}
                            listActiveFields={listActiveFields}
                            listActiveFieldsOptions={listActiveFieldsOptions}
                            saveSettingDispatch={settingsDialogDispatch}
                            expandedAccordion={expandedAccordion}
                            setExpandedAccordion={setExpandedAccordion}
                        />
                        <SortTheDataCollapse
                            viewData={viewData}
                            sortModel={serverSortModel}
                            allColumns={tileColumns}
                            listActiveFields={listActiveFields}
                            listActiveFieldsOptions={listActiveFieldsOptions}
                            saveSettingDispatch={() => { }}
                            expandedAccordion={expandedAccordion}
                            setExpandedAccordion={null}
                        />
                        <GroupTheDataCollapse
                            rowGroupingModel={rowGroupingModel}
                            saveSettingDispatch={() => { }}
                            listActiveFields={listActiveFields}
                            listActiveFieldsOptions={listActiveFieldsOptions}
                            expandedAccordion={expandedAccordion}
                            setExpandedAccordion={null}
                        />
                        <AddCalculationsCollapse
                            allColumns={tileColumns}
                            aggregationModel={aggregationModel}
                            saveSettingDispatch={() => { }}
                            listActiveFieldsOptions={listActiveFieldsOptions}
                            expandedAccordion={expandedAccordion}
                            setExpandedAccordion={null}
                        />
                        <ColorPickerCollapse
                            listActiveFieldsOptions={listActiveFieldsOptions}
                            colorConditions={viewData?.colorConditions}
                            saveSettingDispatch={() => { }}
                            expandedAccordion={expandedAccordion}
                            setExpandedAccordion={null}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpen(false)} className={classes.icon}>
                    {t('common:misc.cancel')}
                </Button>
                <LoadingButton
                    className={classes.icon}
                    style={{
                        minWidth: '80px',
                    }}
                    disabled={
                        isEmpty(viewData)
                    }
                    loadingPosition="start"
                    onClick={handleOk}
                >
                    {t('common:misc.ok')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}