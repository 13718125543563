import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import { getTileListWithPosition } from '../helpers'
import tileApi from 'apis/disApi/tileApi'

function markDuplicateViewKeys(input) {
	try {
		if (!Array.isArray(input)) return []

		const inputClone = [...input]
		const viewKeyMap = new Map()

		// Iterate through the input and mark duplicates
		inputClone.forEach((item) => {
			const { settings: settingsString } = item
			const settings = JSON.parse(settingsString)
			const viewKey = settings.viewKey

			if (viewKeyMap.has(viewKey)) {
				viewKeyMap.get(viewKey).isDuplicate = true
				item.isDuplicate = true
			} else {
				viewKeyMap.set(viewKey, item)
				item.isDuplicate = false
			}
		})

		return inputClone
	} catch {
		return input
	}
}

export const tileKeys = {
	all: ['tiles'],
	allWithKey: (id) => [...tileKeys.all, id],
	details: () => [...tileKeys.all, 'detail'],
	detail: (id) => [...tileKeys.details(), id],
}

export default function useTileQuery({ dashboardKey = undefined, enableConfig = true } = {}) {
	const { environment } = useSelector((state) => state)
	const { dashboard: dashboardKeyInUrl } = useSearchParams()

	const defaultDashboardKey = dashboardKeyInUrl ?? dashboardKey
	const queryKey = tileKeys.allWithKey(defaultDashboardKey)
	const queryClient = useQueryClient()
	const { data, isLoading, isFetching, error } = useQuery({
		queryKey,
		queryFn: () => tileApi.getAll(defaultDashboardKey, environment.apiToken),
		enabled:
			defaultDashboardKey?.trim() !== '' && typeof defaultDashboardKey === 'string' && enableConfig,
	})

	const tileListWithPosition = useMemo(() => {
		if (data?.data?.length > 0) {
			return getTileListWithPosition(data?.data)
		}
		return []
	}, [data?.data])

	const blockedTileListWithPosition = useMemo(() => {
		if (data?.data?.length > 0) {
			const blockedList = getTileListWithPosition(data?.data).map((item) => ({
				...item,
				static: true,
			}))

			const duplicatedList = markDuplicateViewKeys(blockedList)
			return duplicatedList
		}
		return []
	}, [data?.data])

	const addMutation = useMutation(tileApi.add, {
		onSuccess: () => queryClient.invalidateQueries(queryKey),
	})

	const updateMutation = useMutation(tileApi.updateAll, {
		onSuccess: () => queryClient.invalidateQueries(queryKey),
	})

	const removeMutation = useMutation(tileApi.remove, {
		onSuccess: () => queryClient.invalidateQueries(queryKey),
	})

	const handleRefresh = () => queryClient.invalidateQueries(queryKey)

	return {
		isLoading: isLoading && isFetching,
		error,
		tileList: data?.data ?? [],
		tileListWithPosition,
		blockedTileListWithPosition,
		addMutation,
		updateMutation,
		removeMutation,
		onRefreshTile: handleRefresh,
	}
}
