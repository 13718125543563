import { useQuery } from '@tanstack/react-query'
import mapApi from 'apis/disApi/mapApi'
import { DEFAULT_REPEAT_RELOAD_MAP_SECONDS } from './useVehicleLocation'

export const currentVehicleKeys = {
	all: ['current-vehicle-location'],
	detail: (userInfo, vehicleName) => [...currentVehicleKeys.all, userInfo, vehicleName],
}

function useCurrentVehicle({
	userInfo,
	vehicleList,
	vehicleName,
	apiCallIntervalInSeconds = DEFAULT_REPEAT_RELOAD_MAP_SECONDS,
	toDate,
}) {
	const hasToDate = Boolean(toDate)

	const { data, isLoading, isFetching } = useQuery({
		queryKey: currentVehicleKeys.detail(userInfo, vehicleName),
		queryFn: () =>
			mapApi.getCurrentVehicleFromMygeotab({
				userInfo,
				vehicleId: vehicleList?.find((item) => item.name === vehicleName)?.id ?? '',
			}),
		// Feature Reload this API every `apiCallIntervalInSeconds` seconds
		refetchInterval: apiCallIntervalInSeconds * 1000,
		enabled: Boolean(userInfo) && Boolean(vehicleName) && vehicleList?.length > 0 && !hasToDate,
	})

	return {
		data: data?.data?.result ?? [],
		isLoading,
		isFetching,
	}
}

export default useCurrentVehicle
