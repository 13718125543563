import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import CheckboxList from './CheckboxList'

export const DEFAULT_CHECKED_OPTIONS = ['enableActiveUpdate', 'enableExport']

const OtherOptions = ({
	optionList,
	checkedOptions = DEFAULT_CHECKED_OPTIONS,
	onChangeOptions,
	hideScroll,
}) => {
	const { t } = useTranslation('common')

	const handleOtherOptionsCheck = (value) => () => {
		if (!Array.isArray(checkedOptions)) return
		const currentIndex = checkedOptions?.indexOf(value)
		const newChecked = [...checkedOptions]

		if (currentIndex === -1) {
			if (value === 'showRepeatables' && !checkedOptions?.formKey) {
				toast.error('Please select a form first')
			} else {
				newChecked.push(value)
			}
		} else {
			newChecked.splice(currentIndex, 1)
		}

		onChangeOptions(newChecked)
	}

	return (
		<CheckboxList
			title={t('common:dis.otherOptions')}
			data={optionList}
			onItemClick={handleOtherOptionsCheck}
			checkedList={checkedOptions}
			getLabel={(option) => option.label}
			getValue={(option) => option.value}
			checkboxColor="warning"
			hideScroll={hideScroll}
		/>
	)
}

export default OtherOptions
