import { useState, useEffect, useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

import CollapseComponent from './CollapseComponent'
import DoformsDefaultForm from 'custom-components/DoformsDefaultForm'
// import { shouldPreventRender } from '../ViewDialogUtils'

const ChooseAFormComponent = ({
	projectFormInfo,
	setProjectFormInfo,
	expandedAccordion,
	setExpandedAccordion,
	resetViewData,
}) => {
	const [t] = useTranslation('common')
	const title = useMemo(() => t('common:view.chooseAForm'), [t])

	const [value, setValue] = useState('')
	function handleValue(paramValue) {
		paramValue =
			paramValue.length > 40 ? (paramValue = paramValue.substring(37) + '...') : paramValue
		if (paramValue !== value) {
			setValue(paramValue)
		}
	}

	useEffect(() => {
		if (!projectFormInfo?.projectName) {
			setValue('')
			return
		}

		handleValue(projectFormInfo.projectName + ' / ' + projectFormInfo?.formName)
	}, [projectFormInfo])

	return (
		<CollapseComponent
			title={title}
			value={value}
			expanded={expandedAccordion === title}
			//expanded={true}
			setExpandedAccordion={setExpandedAccordion}
			content={
				<DoformsDefaultForm
					// tab={(!environment?.isProjectFormsLoaded ||
					//     environment?.isLoadingProjects) ?
					//     "load" : "projectForm"}
					tab={'projectForm'}
					source="grid"
					projectFormInfo={projectFormInfo}
					handleChange={setProjectFormInfo}
					handleValue={handleValue}
					resetViewData={resetViewData}
				/>
			}
		/>
	)
}

function shouldPreventRender(prevProps, nextProps) {
	return (
		isEqual(prevProps.projectFormInfo, nextProps.projectFormInfo) &&
		isEqual(prevProps.expandedAccordion, nextProps.expandedAccordion)
	)
}

export const ChooseAFormCollapse = memo(ChooseAFormComponent, shouldPreventRender)
