import { Alert, Dialog, DialogActions, DialogContent, Typography, Stack } from '@mui/material'
import DragDropLayout from 'components/pages/dis/components/DragDropLayout'
import AppButton from 'custom-components/AppButton'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import { getNewEmptyPosition } from '../../menu/_utils/common'
import { isEqual, map, unionBy } from 'lodash'
import { isJson } from 'utils/functions/helpers'
import { checkKeyIsExist } from 'components/pages/dis/tile/utils/helpers'

export default function DialogOfTilePosition({
	name,
	open,
	tilePositionList,
	onClose,
	onChangePosition,
}) {
	const { t } = useTranslation('common')
	const { tile: tileKeyInUrl = '' } = useSearchParams()
	const isEditingTile = Boolean(tileKeyInUrl)

	const tilePositionListHide = useMemo(() => {
			return tilePositionList.filter((item) => {
				if (item.type === 'DoFormsForm' && item?.settings && isJson(item?.settings)) {
					const parsedSettings = JSON.parse(item.settings)
				 	if (checkKeyIsExist( 'showInDashboard', parsedSettings?.otherOptionsChecked) === false ){
						return item
				 	}
				}
			}
			)				
		}
	, [tilePositionList])

	const tilePositionListAll = useMemo(() => {
		return tilePositionList			
	}
, [tilePositionList])



	const [initialTiles, setInitialTiles] = useState(() => {
		if (isEditingTile) {
			return tilePositionList.map((tile) => ({ ...tile, static: false }))
		} else {
			const { x, y } = getNewEmptyPosition(tilePositionList)
			const newBox = { i: name, x, y, w: 1, h: 1 }

			return [...tilePositionList, newBox]
		}
	})

	const handleOK = () => {		
		let allTilePos = []
		tilePositionListAll?.map((x) => {
			let item = initialTiles.find((obj) => obj.i === x.i)
			if (item){
				allTilePos.push(item)
			}else{
				allTilePos.push(x)
			}
		})
		setInitialTiles(allTilePos)
		onChangePosition(allTilePos)

		onClose()
	}

	const handleCancel = () => {
		onClose()
	}

	return (
		<div>
			<Dialog
				sx={{
					'& .MuiPaper-root': {
						width: '100% !important',
						maxWidth: '100% !important',
						minHeight: 'calc(100% - 64px) !important',
						overflow: 'hidden !important',
					},
				}}
				open={open}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<Alert severity="info">
						<Typography variant="body1">{t('common:dis.dragDropToMove')}</Typography>
					</Alert>
					<DragDropLayout
						layout={initialTiles}
						onLayoutChange={(layout) => setInitialTiles(layout)}
						isSettingDialog={true}
						isActive
					/>
				</DialogContent>
				<DialogActions>
					<Stack direction="row" spacing={1}>
						<AppButton onClick={handleOK}>{t('common:misc.ok')}</AppButton>
						<AppButton onClick={handleCancel}>{t('common:misc.cancel')}</AppButton>
					</Stack>
				</DialogActions>
			</Dialog>
		</div>
	)
}
