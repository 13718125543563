import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
	ArrowDropDown,
	ArrowDropUp,
	Build,
	Event,
	QuestionMark,
	OpenInNew as OpenInNewIcon,
} from '@mui/icons-material'
import {
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Paper,
	Popover,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
	datesValues,
	nonDatesValues,
	operatorOptions,
} from '../../../utils/functions/conditionsOptions'
import {
	parseAs,
	calculateUserTimezoneOffset,
	fromUTCToTimezoneOffset
} from '../../../utils/functions/doformsDateUtil'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import { showColumnTitleByName } from '../dataHelpers'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'
import enUS from 'date-fns/locale/en-US'
import DoformsConditionsBuilder from './DoformsConditionsBuilder'
import {
	ACTIVITY_DEVICE_LIST,
	ACTIVITY_RECEIVE_TIME_KEY,
	HELP_LINK_CONDITION_FILTERS,
} from '../../../constants'
import { ACTIVITY_ACTIONS } from '../../../reducers/activityReducer'

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		paddingBottom: '8px',
		'& .MuiOutlinedInput-input': {
			cursor: 'pointer',
		},
		'@media (max-width: 1024px)': {
			display: 'flex',
			flexDirection: 'column',
			gap: 16,
		},
	},

	conditionDateSelect: {
		maxWidth: '18em',
		width: '18em',
		marginRight: '8px',
		'& .MuiInputBase-root': {
			cursor: 'pointer',
		},
	},

	conditionNonDateSelect: {
		display: 'inline-flex',
		minWidth: '18em',
	},

	filtersContainer: {
		padding: '16px',
	},

	conditionBuild: {
		margin: '8px',
	},

	footer: {
		// marginTop: "8px",
		display: 'flex',
		minHeight: '52px',
		alignItems: 'center',
		justifyContent: 'end',
	},
}))

const DoformsConditionFilters = (props) => {
	const [t] = useTranslation('common')
	const classes = useStyles()

	const { environment, query, viewData, queryView, tab, onHandleOpenFullScreenTab } = props
	// const [datePresets] = useState(datesValues(t));
	// const [nonDatePresets] = useState(nonDatesValues(t));
	const userOffset = useMemo(() => {
		return calculateUserTimezoneOffset(environment)
	}, [environment])


	const [anchorEl, setAnchorEl] = useState(null)
	const [selectionComponent, setSelectionComponent] = useState(null)
	const isSelectOpen = Boolean(anchorEl)

	const [showBuilder, setShowBuilder] = useState(false)
	const [locale, setLocale] = useState(enUS)
	const [keyDownEnter, setKeyDownEnter] = useState(null)

	const buildQueryBtnRef = useRef(null)

	const builderId = showBuilder ? 'filters-builder' : undefined

	const dispatch = useDispatch()

	const datePresets = useMemo(() => {
		return datesValues(t)
	}, [t])

	const dateOperatorPresets = useMemo(() => {
		return operatorOptions(t)
	}, [t])

	const nonDatePresets = useMemo(() => {
		return nonDatesValues(t)
	}, [t])

	const queryConditions = useMemo(() => {
		if (_.isEmpty(queryView)) return []
		if (!queryView.queries[0].filter) return []
		let conditions = queryView.queries[0].filter.conditions
		if (!conditions[1]) {
			conditions[1] = { preset: 'ALL', target: '@UserStatus', type: 'EQ' }
		} else {
			if (conditions[1].target === '@InProgress') {
				conditions[1].target = '@UserStatus'
			}
			if (conditions[1].type === 'IN') {
				conditions[1].type = 'EQ'
			}
		}

		if (conditions[1].target === ACTIVITY_RECEIVE_TIME_KEY) {
			return [...conditions].reverse()
		}
		return conditions
	}, [queryView])

	const handlePresetChange = (item, conditionType) => {
		let filterElement = conditionType === 'date' ? 0 : 1

		queryConditions[filterElement] = {
			target: queryConditions[filterElement].target,
			type: 'EQ',
			preset: item.preset,
		}
		setSelectionComponent(null)
		onChangedFilterConditions(queryConditions)
	}

	const handleGoToHelpLink = () => {
		window.open(HELP_LINK_CONDITION_FILTERS, '_blank')
	}

	const handleBuildClick = (event) => {
		buildQueryBtnRef.current.blur()
		setAnchorEl(event.currentTarget)
		setShowBuilder(true)
	}

	const handleClose = () => {
		setAnchorEl(null)
		setShowBuilder(false)
		setKeyDownEnter(null)

		dispatch({
			type: ENV_ACTIONS.FORM_COLUMNS_CHANGED,
			payload: [],
		})
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			const entering = keyDownEnter === null ? true : !keyDownEnter
			setKeyDownEnter(entering)
		}
	}

	const onChangedFilterConditions = (newConditions) => {
		switch (tab) {
			case 'lookups':
				dispatch({
					type: LOOKUPS_ACTIONS.FORM_FILTER_CONDITIONS,
					payload: newConditions,
				})
				dispatch({
					type: LOOKUPS_ACTIONS.DATA_GRID,
					payload: {
						query: {
							...query,
							filter: {
								conditions: newConditions,
							},
						},
					},
				})
				dispatch({
					type: LOOKUPS_ACTIONS.LOOKUPS_REFRESH,
					payload: true,
				})
				break
			case 'views':
				dispatch({
					type: VIEWS_ACTIONS.FORM_FILTER_CONDITIONS,
					payload: newConditions,
				})
				dispatch({
					type: VIEWS_ACTIONS.DATA_GRID,
					payload: {
						query: {
							...query,
							filter: {
								conditions: newConditions,
							},
						},
					},
				})
				dispatch({
					type: VIEWS_ACTIONS.VIEW_REFRESH,
					payload: true,
				})
				break
			case 'devices':
				dispatch({
					type: DEVICES_ACTIONS.FORM_FILTER_CONDITIONS,
					payload: newConditions,
				})
				dispatch({
					type: DEVICES_ACTIONS.DATA_GRID,
					payload: {
						query: {
							...query,
							filter: {
								conditions: newConditions,
							},
						},
					},
				})
				dispatch({
					type: DEVICES_ACTIONS.DEVICE_REFRESH,
					payload: true,
				})
				break
			case 'activity':
				dispatch({
					type: ACTIVITY_ACTIONS.FORM_FILTER_CONDITIONS,
					payload: newConditions,
				})
				dispatch({
					type: ACTIVITY_ACTIONS.DATA_GRID,
					payload: {
						query: {
							...query,
							filter: {
								conditions: newConditions,
							},
						},
					},
				})
				dispatch({
					type: ACTIVITY_ACTIONS.ACTIVITY_REFRESH,
					payload: true,
				})
				break
			default: //FORMS
				dispatch({
					type: FORM_ACTIONS.FORM_FILTER_CONDITIONS,
					payload: newConditions,
				})
				dispatch({
					type: FORM_ACTIONS.DATA_GRID,
					payload: {
						query: {
							...query,
							filter: {
								conditions: newConditions,
							},
						},
					},
				})
				dispatch({
					type: FORM_ACTIONS.FORM_REFRESH,
					payload: true,
				})
				break
		}
	}

	const showPresetName = (preset, conditionType) => {
		if (!preset) return ''
		if (conditionType === 'date') {
			let presetSelected = dateOperatorPresets.find((item) => item.type === preset)
			if (presetSelected != null) {
				return presetSelected.name
			} else {
				return datePresets.find((item) => item.preset === preset).name
			}
		} else if (conditionType === 'nonDate') {
			return nonDatePresets.find((item) => item.preset === preset).name
		}
	}

	const showConditionValue = (condition, conditionType) => {
		//let value = conditionType === "date"? formatDate(condition.values[0], environment.userCurrent.time.timezone.offset, 'MM/DD/YYYY'): condition.values[0];
		let value = condition.values[0]
		value = !value ? t('common:filters.empty') : value
		let endValue
		// let type = condition.type === "IN"? condition.type: "EQ"

		switch (condition.type) {
			case 'EQ':
				if (conditionType === 'date') {
					value = fromUTCToTimezoneOffset(value, userOffset, 'MM/DD/YYYY')
				}
				if (value === t('common:filters.empty'))
					return t('common:filters.is').toLowerCase() + ' ' + value
				return value
			case 'NE':
				if (conditionType === 'date') {
					value = fromUTCToTimezoneOffset(value, userOffset, 'MM/DD/YYYY')
				}
				if (value === t('common:filters.empty'))
					return t('common:filters.isNot').toLowerCase() + ' ' + value
				return t('common:filters.notEqual').toLowerCase() + ' ' + value
			case 'CT':
				if (conditionType === 'date') {
					value = fromUTCToTimezoneOffset(value, userOffset, 'MM/DD/YYYY')
				}
				return t('common:filters.contains').toLowerCase() + ' ' + value
			case 'BT':
				let startValue = value
				if (conditionType === 'date') {
					//TODO (remove offset): startValue = formatDate(condition.values[0], environment.userCurrent.time.timezone.offset, 'MM/DD/YYYY');
					startValue = fromUTCToTimezoneOffset(value, userOffset, 'MM/DD/YYYY')
					endValue = !condition.values[1]
						? t('common:filters.empty')
						: //TODO (remove offset): formatDate(condition.values[1], environment.userCurrent.time.timezone.isDst?environment.userCurrent.time.timezone.offset - 60 - 1:environment.userCurrent.time.timezone.offset - 1, 'MM/DD/YYYY');
						fromUTCToTimezoneOffset(condition.values[1], userOffset, 'MM/DD/YYYY')
				} else {
					endValue = !condition.values[1] ? t('common:filters.empty') : condition.values[1]
				}
				return `${startValue} ${t('common:filters.to').toLowerCase()} ${endValue}`
			default:
				return t('common:filters.empty')
		}
	}

	const handleSelectOpen = (event) => {
		// if(isBuildSelected) return;
		const targetFilter = event.currentTarget.title
		if (targetFilter === 'nonDateCondition') {
			if (queryConditions[1].target !== '@UserStatus') return
		}

		setAnchorEl(event.currentTarget)
		setSelectionComponent(targetFilter)
	}

	const handleSelectClose = () => {
		setAnchorEl(null)
		setSelectionComponent(null)
	}

	const renderPresetSelection = () => (
		<Popover
			anchorEl={anchorEl}
			id={'condition-presets-container'}
			keepMounted
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={isSelectOpen}
			onClose={handleSelectClose}
			PaperProps={{
				style: { width: '18em' },
			}}
		>
			<List component="nav" dense={true} aria-labelledby="nested-list-subheader">
				{selectionComponent === 'dateCondition' &&
					datePresets &&
					datePresets.map(
						(item, index) =>
							item.preset !== 'CUSTOM' && (
								<ListItemButton
									key={index}
									disabled={item.preset === queryConditions[0].preset}
									onClick={() => handlePresetChange(item, 'date')}
								>
									<ListItemText
										primary={
											<Typography variant={'body1'} fontSize={14}>
												{item.name}
											</Typography>
										}
									/>
								</ListItemButton>
							)
					)}
				{selectionComponent === 'nonDateCondition' &&
					nonDatePresets &&
					nonDatePresets.map((item, index) => (
						<ListItemButton
							key={index}
							disabled={item.preset === queryConditions[1].preset}
							onClick={() => handlePresetChange(item, 'nonDate')}
						>
							<ListItemText
								primary={
									<Typography variant={'body1'} fontSize={14}>
										{item.name}
									</Typography>
								}
							/>
						</ListItemButton>
					))}
			</List>
		</Popover>
	)

	const handleDateChange = (newValue) => {
		if (
			newValue &&
			(newValue instanceof Date || (moment.isMoment(newValue) && newValue.isValid()))
		) {
			// queryConditions.map((item, index) => index === 0? {...item, values: [newValue]}:item);
			if (queryConditions && queryConditions[0]) {
				setSelectionComponent(null)
				const startDayMoment = moment(newValue).clone().startOf('day')
				const userMoment = parseAs(startDayMoment, userOffset)
				if (userMoment.isValid()) {
					const formatQueryConditions = queryConditions.map((item, index) =>
						index === 0 ? { ...item, values: [userMoment.toISOString()] } : item
					)
					onChangedFilterConditions(formatQueryConditions)
				}
			}
		}
	}

	useEffect(() => {
		const importLocaleFile = async () => {
			const localeToSet = await import(
				`date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
			)
			setLocale(localeToSet.default)
		}

		if (locale.code !== t('common:languages.dateFnsLocale')) {
			importLocaleFile()
		}
	}, [t('common:languages.dateFnsLocale')])
	const renderDateCondition = () => {
		const label = queryConditions[0]
			? showColumnTitleByName(queryConditions[0].target, viewData?.columns, t)
			: t('common:filters.startDate')
		let value = queryConditions[0]
			? queryConditions[0].preset
				? showPresetName(queryConditions[0].preset, 'date')
				: showConditionValue(queryConditions[0], 'date')
			: t('common:filters.loading')

		const isSelectable = queryConditions[0]
			? queryConditions[0].preset ||
			((queryConditions[0].target === '@StartTime' ||
				queryConditions[0].target === ACTIVITY_RECEIVE_TIME_KEY) &&
				(queryConditions[0].type === 'BT' ||
					queryConditions[0].type === 'CT' ||
					queryConditions[0].type === 'NE' ||
					(queryConditions[0].type === 'EQ' &&
						(!queryConditions[0].values || queryConditions[0].values.length === 0))))
			: false
		const inputProps = isSelectable
			? {
				endAdornment: isSelectOpen ? (
					<ArrowDropUp position="end" />
				) : (
					<ArrowDropDown position="end" />
				),
			}
			: {
				endAdornment: (
					<IconButton aria-label="delete" size="small">
						{' '}
						<Event />
					</IconButton>
				),
			}

		// Fix error default date when chosen operator
		if (['contains ', 'not equals ', ''].includes(value)) {
			let momentObj = moment()
			if (momentObj.isValid()) {
				value = `${value.slice(0, -1)} ${momentObj.format('MM/DD/YYYY')}`
			}
		}

		if (isSelectable || !queryConditions[0]) {
			return (
				<TextField
					id="dateCondition"
					title="dateCondition"
					className={classes.conditionDateSelect}
					size="small"
					color="primary"
					variant="outlined"
					onClick={isSelectable ? handleSelectOpen : null}
					label={label}
					value={value ? value : t('common:filters.today')}
					InputProps={inputProps}
				/>
			)
		} else {
			return (
				<LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
					<DatePicker
						id="map-date"
						label={label}
						value={value}
						onChange={handleDateChange}
						renderInput={(params) => (
							<TextField
								{...params}
								className={classes.conditionDateSelect}
								size="small"
								variant="outlined"
								label={label}
							></TextField>
						)}
					/>
				</LocalizationProvider>
			)
		}
	}

	const renderNonDateCondition = () => {
		const label = queryConditions[1]
			? showColumnTitleByName(queryConditions[1].target, viewData?.columns, t)
			: t('common:filters.status')
		const value = queryConditions[1]
			? queryConditions[1].preset
				? showPresetName(queryConditions[1].preset, 'nonDate')
				: showConditionValue(queryConditions[1], 'nonDate')
			: t('common:filters.loading')
		const isSelectable = queryConditions[1]
			? queryConditions[1].target === '@UserStatus' && queryConditions[1].preset
			: false
		const inputProps = isSelectable
			? {
				endAdornment: isSelectOpen ? (
					<ArrowDropUp position="end" />
				) : (
					<ArrowDropDown position="end" />
				),
			}
			: { readOnly: true }

		if (ACTIVITY_DEVICE_LIST.includes(viewData?.type)) return null
		return (
			<TextField
				id="nonDateCondition"
				title="nonDateCondition"
				className={classes.conditionDateSelect}
				size="small"
				color="primary"
				variant="outlined"
				onClick={isSelectable ? handleSelectOpen : handleBuildClick}
				label={label}
				value={value}
				InputProps={inputProps}
			/>
		)
	}

	const renderFilters = () => (
		<Popover
			target={builderId}
			open={showBuilder}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			onKeyDown={handleKeyDown}
			onClose={handleClose}
		>
			<Paper className={classes.filtersContainer}>
				<DoformsConditionsBuilder
					columns={
						environment.formColumnsChanged.length > 0
							? environment.formColumnsChanged
							: viewData?.columns
					}
					environment={environment}
					query={query}
					tab={tab}
					viewData={viewData}
					conditions={queryConditions}
					iconsTheme={environment.iconsTheme}
					keyDownEnter={keyDownEnter}
					onBuilderClosed={handleClose}
				/>
			</Paper>
		</Popover>
	)

	return (
		<>
			<form className={classes.root}>
				{renderDateCondition()}
				{renderNonDateCondition()}
				<div style={{ display: 'inline-flex', marginTop: '-4px' }}>
					<Tooltip
						title={`${t('tooltip.buildQuery')}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<span>
							<IconButton
								ref={buildQueryBtnRef}
								sx={environment.iconsTheme}
								aria-describedby={builderId}
								className={classes.conditionBuild}
								disabled={!queryConditions.length}
								onClick={handleBuildClick}
								size="small"
								aria-label="build query"
							>
								<Build fontSize="small" />
							</IconButton>
						</span>
					</Tooltip>
					<span>
						<IconButton
							sx={environment.iconsTheme}
							aria-describedby={'help link'}
							className={classes.conditionBuild}
							onClick={handleGoToHelpLink}
							size="small"
							aria-label="help link"
						>
							<QuestionMark fontSize="small" />
						</IconButton>
					</span>
					<Tooltip
						title={`${t('tooltip.openInNewTab')}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<span>
							<IconButton
								sx={environment.iconsTheme}
								className={classes.conditionBuild}
								onClick={() => onHandleOpenFullScreenTab()}
								size="small"
								aria-label="Open in new tab"
							>
								<OpenInNewIcon fontSize="small" />
							</IconButton>
						</span>
					</Tooltip>
				</div>
				{renderFilters()}
				{selectionComponent && renderPresetSelection()}
			</form>
		</>
	)
}

export default DoformsConditionFilters
